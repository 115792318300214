/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Checkbox, Input, Table, Select, notification, Dropdown } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
  SmileOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  COURSES_LIST,
  SUBSECTIONS_DATA,
  SEMESTER_COURSE_REGISTRATION_ADD,
  SEMESTER_COURSE_REGISTRATION_REMOVE,
  SEMESTER_COURSE_REGISTRATION_UPDATE,
} from "../../apis/apis";
import SemesterSubSectionBox from "./SemesterSubSectionBox";
import SisLoader from "../../widgets/loader";
import { render } from "@testing-library/react";
import DropCourseDrawer from "./Student-Course-Request/DropCourseDrawer";
import WithdrawFromSemesterDrawer from "./Student-Course-Request/WithdrawFromSemesterDrawer";
const SemesterSectionBox = (props) => {
  const {
    item,
    course_ids,
    set_course_ids,
    registration_status,
    calendar_id,
    semester_id,
    student_id,
    isDropdownOpen,
    setIsDropdownOpen,
    semester_registration,
    program,
  } = props;
  const sectionCourse = item?.section?.course_list;
  const subsections = item?.subsections;
  const sub_section_id = "";
  const section_id = item?.id;
  const [show_form, set_show_form] = useState(false);
  const [loader, set_loader] = useState(true);
  const [table_list, set_table_list] = useState([]);
  const [table_list_2, set_table_list_2] = useState([]);
  const [error, setError] = useState(null);
  const [subSection_data, set_subSection_data] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [disabled_add_course, set_disabled_add_course] = useState(false);
  const [disabled_drop_course, set_disabled_drop_course] = useState(false);
  const [dropcoursedrawerOpen, setDropCourseDrawerOpen] = useState(false);
  const [withdrawfromsemesterdrawerOpen, setWithdrawFromSemesterDrawerOpen] = useState(false);
  const [selectedWithdraw, setSelectedWithdraw] = useState(false);
  
  const handleDropdownOpenChange = (open) => {
    setIsDropdownOpen(open);
  };

  const fetchSubSectionData = async (sec_id) => {
    set_loader(true)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("section_id", sec_id ? sec_id : "");
    const API_RESPONSE = await SUBSECTIONS_DATA(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_subSection_data(API_RESPONSE?.data?.sections_with_subsections[0]);
      set_loader(false);

    } else {
      setError(API_RESPONSE?.data?.errors);
      set_loader(false);
    }

  }

  useEffect(() => {

    // fetchSubSectionData();

  }, []);



  const set_sem_course = (e, item) => {
    if (e.target.checked) {
      set_course_ids([...course_ids, item]);
    } else {
      var newsData = course_ids.filter((itemA) => itemA !== item);
      set_course_ids(newsData);
    }
  };

  // Given Credits
  const given_credit = (data) => {
    return (
      <div>
        <div className="input-box">
          {/* {data?.rooms && data?.timing && data?.faculty ? ( */}
            {data?.rooms && data?.faculty ? (
            <>
              {data?.course_semester_registration?.course_status >= 2 ? <>
                {data?.course_semester_registration?.given_credits}
              </> : <>
                {data?.course_semester_registration && data?.checked ? (
                  <>
                    {data?.transferred_course ? (
                      <>
                        {data?.transferred?.credit_earn === data?.units ? (
                          <></>
                        ) : (
                          <>
                            <Select
                              defaultValue={data?.course_semester_registration?.given_credits}
                              placeholder="--Select Credit--"
                              style={{ width: "100px" }}
                              onChange={(value) =>
                                UPDATE_API(
                                  calendar_id,
                                  semester_id,
                                  student_id,
                                  data.id,
                                  section_id,
                                  sub_section_id,
                                  data?.children_id,
                                  data?.rooms?.id,
                                  data?.timing,
                                  data?.faculty?.id,
                                  value,
                                  data?.course_status,
                                  data?.course_semester_registration?.id
                                )
                              }
                            >
                              {/* {Array(
                                parseInt(data.units) -
                                parseInt(data.transferred_course.credit_earn)
                              )
                                .fill()
                                .map((item, index) => (
                                  <>
                                    <Select.Option value={index + 1}>
                                      {index + 1}
                                    </Select.Option>
                                  </>
                                ))} */}
                              {Array(parseInt(data?.units))
                                .fill()
                                .map((item, index) => (
                                  <Select.Option value={parseInt(data?.units) - index} >
                                    {parseInt(data?.units) - index}  
                                  </Select.Option>
                                ))}
                            </Select>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Select
                          defaultValue={
                            data?.course_semester_registration?.given_credits
                          }
                          placeholder="--Select Credit--"
                          style={{ width: "100px" }}
                          onChange={(value) =>
                            UPDATE_API(
                              calendar_id,
                              semester_id,
                              student_id,
                              data.id,
                              section_id,
                              sub_section_id,
                              data?.children_id,
                              data?.rooms?.id,
                              data?.timing,
                              data?.faculty?.id,
                              value,
                              data?.course_status,
                              data.course_semester_registration?.id
                            )
                          }
                        >
                          {/* {Array(parseInt(data.units))
                            .fill()
                            .map((item, index) => (
                              <>
                                <Select.Option value={index + 1}>
                                  {index + 1}
                                </Select.Option>
                              </>
                            ))} */}
                          {Array(parseInt(data?.units))
                            .fill()
                            .map((item, index) => (
                              <Select.Option value={parseInt(data?.units) - index} >
                               {parseInt(data?.units) - index}  
                              </Select.Option>
                            ))}
                        </Select>
                      </>
                    )}
                  </>
                ) : (
                  <>Not Registered</>
                )}
              </>}
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>Not Available</span>
            </>
          )}
        </div>
      </div>
    );
  };

  const get_faculty = (data) => {
    return (
      <>
        {/* {data?.rooms && data?.timing && data?.faculty ? ( */}
        {data?.rooms && data?.faculty ? (
          <>{data?.faculty?.name}</>
        ) : (
          <>
            <span style={{ color: "red" }}>Not Available</span>
          </>
        )}
      </>
    );
  };


  // Room
  const course_room = (data) => {
    return (
      <div
        style={{
          width: "130px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        {/* {data?.rooms && data?.timing && data?.faculty ? ( */}
        {data?.rooms && data?.faculty ? (
          <>
            <div className="theme-content-left-head">
              <p>{data?.rooms?.room_name}</p>
            </div>
            <div className="theme-content-right-head">
              <h4>{data?.rooms?.capacity}</h4>
            </div>
          </>
        ) : (
          <>
            <span style={{ color: "red" }}>Not Available</span>
          </>
        )}
      </div>
    );
  };


  const get_timing = (data, selected_days) => {
    return (
      <>
        {selected_days.map((item) => (
          <>
            {item?.start_time && (
              <>
                {data?.rooms && data?.timing && data?.faculty ? (
                  <>
                    <span
                      style={{
                        width: "110px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {item?.value?.slice(0, 2).toUpperCase()}{" "}
                      {item?.start_time} - {item?.end_time}
                    </span>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "red" }}>Not Available</span>
                  </>
                )}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  // Course Status
  const course_status = (data) => {
    return (
      <>
        <div className="input-box">
          {/* {data?.rooms && data?.timing && data?.faculty ? ( */}
          {data?.rooms && data?.faculty ? (
            <>
              {data?.course_semester_registration && data?.checked ? (
                <>

                  {parseInt(registration_status) > 1 && parseInt(registration_status) < 4 && data?.checked ? <>
                    {data?.course_semester_registration?.course_status === 2 && (
                      <>Registered</>
                    )}
                    {data?.course_semester_registration?.course_status === 3 && (
                      <>Completed</>
                    )}
                    {data?.course_semester_registration?.course_status === 4 && (
                      <>Withdrawn</>
                    )}
                    {data?.course_semester_registration?.course_status === 5 && (
                      <>Graded</>
                    )}
                    {data?.course_semester_registration?.course_status === 6 && (
                      <>Repeated</>
                    )}
                    {data?.course_semester_registration?.course_status === 7 && (
                      <>Partial Completed</>
                    )}
                    {data?.course_semester_registration?.course_status === 8 && (
                      <>Drop</>
                    )}
                  </> : 'In Progress'}

                </>
              ) : (
                <>Not Registered</>
              )}
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>Not Available</span>
            </>
          )}

          {/* <Select placeholder='--select course status--' style={{width:"150px"}} >
                      <Select.Option value='Completed'>Completed</Select.Option>
                      <Select.Option value='Graded'>Graded</Select.Option>
                      <Select.Option value='Not Registered'>Not Registered</Select.Option>
                      <Select.Option value='Registered'>Registered</Select.Option>
                  </Select> */}
        </div>
      </>
    );
  };


  const ADD_API = async (
    calendar_id,
    semester_id,
    student_id,
    course_id,
    section_id,
    sub_section_id,
    class_section_id,
    room_id,
    timing,
    faculty,
    given_credits,
    course_status,
  ) => {
    set_loader(true);
    // alert(sub_section_id)
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", ((section_id === null) || (section_id === undefined)) ? "" : section_id);
    FORM_DATA.append("sub_section_id", ((sub_section_id === null) || (sub_section_id === undefined)) ? "" : sub_section_id);
    FORM_DATA.append("class_section_id", ((class_section_id === null) || (class_section_id === undefined)) ? "" : class_section_id);
    FORM_DATA.append("room_id", room_id);
    FORM_DATA.append("timing", timing);
    FORM_DATA.append("faculty", faculty);
    FORM_DATA.append("given_credits", given_credits);

    FORM_DATA.append("course_status", course_status);
    FORM_DATA.append("program_id", program?.id);
    const API_RESPONSE = await SEMESTER_COURSE_REGISTRATION_ADD(FORM_DATA);

    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Student Successfully register in course!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      fetchSubSectionData(section_id)
    } else {
      notification?.open({
        message: "Note!!",
        description: "Student already register in course!",
        icon: <SmileOutlined style={{ color: "yellow" }} />,
      });
      set_loader(false);
    }
  };
  const UPDATE_API = async (
    calendar_id,
    semester_id,
    student_id,
    course_id,
    section_id,
    sub_section_id,
    class_section_id,
    room_id,
    timing,
    faculty,
    given_credits,
    course_status,
    id
  ) => {
    set_loader(true);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", calendar_id);
    FORM_DATA.append("semester_id", semester_id);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", ((section_id === null) || (section_id === undefined)) ? "" : section_id);
    FORM_DATA.append("sub_section_id", ((sub_section_id === null) || (sub_section_id === undefined)) ? "" : sub_section_id);
    FORM_DATA.append("class_section_id", ((class_section_id === null) || (class_section_id === undefined)) ? "" : class_section_id);
    FORM_DATA.append("room_id", room_id);
    FORM_DATA.append("timing", timing);
    FORM_DATA.append("faculty", faculty);
    FORM_DATA.append("id", id);
    FORM_DATA.append("given_credits", given_credits);
    FORM_DATA.append("course_status", course_status);
    FORM_DATA.append("program_id", program?.id);

    const API_RESPONSE = await SEMESTER_COURSE_REGISTRATION_UPDATE(FORM_DATA);

    if (API_RESPONSE?.data?.status) {
      notification?.open({
        message: "Success!!",
        description: "Student course Successfully updated!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      fetchSubSectionData(section_id)
    } else {
      notification?.open({
        message: "Note!!",
        description: "Student already register in course!",
        icon: <SmileOutlined style={{ color: "yellow" }} />,
      });
      set_loader(false);
    }
  };
  const REMOVE_API = async (
    id,
    // calendar_id,
    // semester_id,
    // student_id,
    // course_id,
    section_id,
    // sub_section_id,
    // class_section_id,
    // room_id,
    // timing,
    // faculty,
    // given_credits,
    // course_status,
    // checked
  ) => {
    set_loader(true);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("program_id", program?.id);
    // FORM_DATA.append("calendar_id", calendar_id);
    // FORM_DATA.append("semester_id", semester_id);
    // FORM_DATA.append("student_id", student_id);
    // FORM_DATA.append("course_id", course_id);
    FORM_DATA.append("section_id", ((section_id === null) || (section_id === undefined)) ? "" : section_id);
    // FORM_DATA.append("sub_section_id", ((sub_section_id === null) || (sub_section_id === undefined)) ? "" : sub_section_id);
    // FORM_DATA.append("class_section_id",((class_section_id === null) || (class_section_id === undefined)) ? "" : class_section_id);
    // FORM_DATA.append("room_id", room_id);
    // FORM_DATA.append("timing", timing);
    // FORM_DATA.append("faculty", faculty);
    // FORM_DATA.append("given_credits", given_credits);
    // FORM_DATA.append("course_status", course_status);
    // FORM_DATA.append("checked", checked);
    const API_RESPONSE = await SEMESTER_COURSE_REGISTRATION_REMOVE(FORM_DATA);

    if (API_RESPONSE?.data?.status) {
      notification?.open({
        message: "Success!!",
        description: "Student Successfully removed in course!",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      set_loader(false);
      fetchSubSectionData(section_id)
    }
  };
  const columns = [

    {
      title: "Courses",
      render: (text, record) => {
        return (
          <>
            {record?.course_semester_registration ? (
              <>
                <label style={{ position: "relative", course: "pointer" }}>
                  {record?.course_semester_registration.course_status >= 2 && record?.checked ? (
                    <>
                      <Checkbox
                        disabled
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                        checked
                      />
                    </>
                  ) : (
                    <>
                      <Checkbox
                        checked={record.checked}
                        onChange={(e) => {
                          record.checked ? REMOVE_API(record?.course_semester_registration?.id, record?.section_id,) :
                            ADD_API(
                              calendar_id,
                              semester_id,
                              student_id,
                              record.id,
                              record?.section_id,
                              record?.children_id,
                              record?.children_id,
                              record?.rooms?.id,
                              record?.timing,
                              record?.faculty?.id,
                              // 1,
                              record?.units,
                              record?.transferred?.credit_earn === record?.units ? 3 : record?.transferred?.credit_earn < record?.units ? 7 : 1,
                            )
                        }
                        }
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                      />
                    </>
                  )}

                  <div style={{ minWidth: "350px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: "22px",
                        course: "pointer",
                      }}
                    >
                      {" "}
                      {record?.title}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "1px 7px",
                          borderRadius: "5px",
                        }}
                      >
                        SEC-{record?.section_name}
                      </span>
                      {record?.transferred_course && (
                        <>
                          <i
                            title="Transferred course"
                            style={{
                              background: "black",
                              marginLeft: "10px",
                              padding: "2px 7px",
                              color: "#fff",
                              borderRadius: "50px",
                            }}
                          >
                            T
                          </i>
                        </>
                      )}
                    </span>
                  </div>
                </label>
              </>
            ) : (
              <>
                <label style={{ position: "relative", course: "pointer" }}>
                  {/* {record?.rooms && record?.timing && record?.faculty ? ( */}
                  {record?.rooms && record?.faculty ? (
                    <>
                      <Checkbox
                        onChange={(e) =>
                          ADD_API(
                            calendar_id,
                            semester_id,
                            student_id,
                            record.id,
                            record?.section_id,
                            record?.children_id,
                            record?.children_id,
                            record?.rooms?.id,
                            record?.timing,
                            record?.faculty?.id,
                            // 1,
                            record?.units,
                            record?.transferred?.credit_earn === record?.units ? 3 : record?.transferred?.credit_earn < record?.units ? 7 : 1
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <div style={{ minWidth: "350px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: "22px",
                        course: "pointer",
                      }}
                    >
                      {" "}
                      {record.title}{" "}
                      <span
                        style={{
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "1px 7px",
                          borderRadius: "5px",
                        }}
                      >
                        SEC-{record?.section_name}
                      </span>
                      {record?.transferred_course && (
                        <>
                          <i
                            title="Transferred course"
                            style={{
                              background: "black",
                              marginLeft: "10px",
                              padding: "2px 7px",
                              color: "#fff",
                              borderRadius: "50px",
                            }}
                          >
                            T
                          </i>
                        </>
                      )}
                    </span>
                  </div>
                </label>
              </>
            )}
          </>
        );
      },
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Courses Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Courses Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Total Credits",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Given Credits",
      render: (text, record) => {
        return given_credit(record);
      },
    },

    {
      title: "Room",
      render: (text, record) => {
        return course_room(record);
      },
    },
    {
      title: "Timing",
      render: (text, record) => {
        var timingData = record?.timing ? JSON?.parse(record.timing) : null;
        return <>{timingData && get_timing(record, timingData)}</>;
      },
    },
    {
      title: "Faculty",
      render: (text, record) => {
        return get_faculty(record);
      },
    },
    {
      title: "Course Status",
      render: (text, record) => {
        return course_status(record);
      },
    },
    {
      title:"Action",
      render: (text, record) => {
        const handleMenuClick = (e, record) => {
          // if (e.key === "1") {
          // //   navigate(
          // //     "/add-course/" + btoa(semester_id)
          // //   )
          // }
          if (e.key === "2") {
            setSelectedRecord(record);
            setDropCourseDrawerOpen(true);
          //   navigate("/drop-course/" + btoa(record.id));
          }
          if (e.key === "3") {
            setSelectedWithdraw(true);
            setWithdrawFromSemesterDrawerOpen(true);
            // setSelectedRecord(record);
          //   navigate("/withdraw-semester");
          }
        };
  
        const items = [
          // {
          //   key: "1",
          //   label: "Add a course",
          //   disabled: disabled_add_course,
          // },
          {
            key: "2",
            label: "Drop the course",
            // disabled: disabled_add_course || disabled_drop_course,
            disabled: record?.course_semester_registration?.course_status > 2 ? true : false,
          },
          {
            key: "3",
            label: "Withdraw from semester",
            disabled: record?.course_semester_registration?.course_status > 2 ? true : false,
          },
        ];
        return record?.course_semester_registration && semester_registration ? (
          <>
          <Dropdown
              menu={{ items, onClick: (e) => handleMenuClick(e, record) }}
              onOpenChange={handleDropdownOpenChange} 
              trigger={["click"]}
            >
              <span
                onClick={(e) => e.preventDefault()}
                style={{ cursor: "pointer" }}
              >
                &#8942; {/* Vertical ellipsis icon */}
              </span>
            </Dropdown>
            {selectedRecord ? (
                <>
         <DropCourseDrawer
          dropcoursedrawerOpen={dropcoursedrawerOpen}
          setDropCourseDrawerOpen={setDropCourseDrawerOpen}
          selectedRecord={selectedRecord}/>
         </>
              ) : (
                <></>
              )}
               {selectedWithdraw && (
              <WithdrawFromSemesterDrawer
              withdrawfromsemesterdrawerOpen={withdrawfromsemesterdrawerOpen}
              setWithdrawFromSemesterDrawerOpen={setWithdrawFromSemesterDrawerOpen}
              />
            )}
         </>
        ):   <span>-</span>
      },
    }
  ];
  return (
    <div className="sis-section">
      <div className="sis-section-head">
        <h4>{item.title}</h4>
        <div>
          Units-{item.units}
          {show_form ? (
            <b onClick={() => set_show_form(false)}>
              <UpOutlined />{" "}
            </b>
          ) : (
            <b onClick={() => { set_show_form(true); fetchSubSectionData(item?.id) }}>
              {" "}
              <DownOutlined />{" "}
            </b>
          )}
        </div>
      </div>

      {show_form && (
        <>
          {

            loader

              ?

              (
                <SisLoader />
              )

              :

              (

                subSection_data?.subsections?.length > 0

                  ?

                  (
                    <>
                      {subSection_data?.subsections && subSection_data?.subsections?.length > 0 && <>
                        {subSection_data?.subsections?.map((sub_item) => (
                          <>
                            <SemesterSubSectionBox
                              calendar_id={calendar_id}
                              semester_id={semester_id}
                              set_loader={set_loader}
                              student_id={student_id}
                              course_ids={course_ids}
                              registration_status={registration_status}
                              set_course_ids={set_course_ids}
                              sub_section_id={sub_item.id}
                              section_id={section_id}
                              item={sub_item}
                              fetchSubSectionData={fetchSubSectionData}
                              isDropdownOpen={isDropdownOpen} 
                              setIsDropdownOpen={setIsDropdownOpen}
                              semester_registration={semester_registration}
                              program={program}
                            />
                          </>
                        ))}
                      </>
                      }

                    </>
                  )

                  :

                  (
                    <>
                      <div className="sis-section-container">
                        <Table
                          defaultExpandAllRows={true}
                          columns={columns}
                          dataSource={subSection_data?.section?.course_list}
                        />
                      </div>
                    </>
                  )


              )
          }
        </>
      )}

    </div>
  );
};

export default SemesterSectionBox;
