import { Select, Radio, Input, Button, Spin, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SIS_STORAGE, FINANCE_DECLARATION_SAVE, GET_FINANCE_DECLARATION } from "../../apis/apis";
import { CloseOutlined, CloudUploadOutlined, LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { CountryDropdown } from "react-country-region-selector";
import SisLoader from "../../widgets/loader";


const FinanceDeclaration = ({id}) => {

    const [fees_payee, set_fees_payee] = useState('')
    const [fund_source, set_fund_source] = useState('')
    const [source_name, set_source_name] = useState('')
    const [source_statement, set_source_statement] = useState("");
    const [api_source_statement, api_set_source_statement] = useState("");
    const [sponsor_first_name, set_sponsor_first_name] = useState("");
    const [sponsor_middle_name, set_sponsor_middle_name] = useState("");
    const [sponsor_last_name, set_sponsor_last_name] = useState("");
    const [sponsor_relationship, set_sponsor_relationship] = useState("");
    const [sponsor_citizenship, set_sponsor_citizenship] = useState("");
    const [sponsor_address1, set_sponsor_address1] = useState("");
    const [sponsor_address2, set_sponsor_address2] = useState("");
    const [sponsor_address_city, set_sponsor_address_city] = useState("");
    const [sponsor_address_state, set_sponsor_address_state] = useState("");
    const [sponsor_address_postal, set_sponsor_address_postal] = useState("");
    const [sponsor_address_country, set_sponsor_address_country] = useState("");
    const [sponsor_phone, set_sponsor_phone] = useState("");
    const [phoneerror, set_Phone_Error] = useState('');
    const [sponsor_email, set_sponsor_email] = useState("");
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);

    const navigate = useNavigate();

    const sourceStatementImage = useRef(null);

    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (localStorgeData.role === 2074) {
          return true;
        }
        var check_permissions = [];
        if (type === 'create') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }
    
        if (check_permissions.length > 0) {
          return true;
        } else {
          return false;
        }
      }

    // handle Image Change

    const handleImageChange = (e, type) => {
        const files = e.target.files;
        if (type === "source_statement") {
            set_source_statement(files[0]);
            // set_api_high_school_diploma_and_transcripts(files[0]);
        }
    }

    // handleRemove Image
    const handleRemoveImage = (e, type) => {
        if (type === "source_statement") {
            set_source_statement("");
            sourceStatementImage.current.value = ''
        }
    }

    useEffect(() => {
         VIEW_FINANCE_DECLARATION();
    }, []);

    const FINANCE_DECLARATION_DRAFT_NEXT = async (finances_save_status) => {
        if (!phoneerror) {
            set_loader(true);
            const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
            const FORM_DATA = new FormData();
            FORM_DATA.append('token', USER_DATA.token);
            FORM_DATA.append('student_user_id', atob(id));
            FORM_DATA.append('finances_save_status', finances_save_status);
            FORM_DATA.append('fees_payee', (fees_payee === null) || (fees_payee === "undefined") || (fees_payee === undefined) || (fees_payee === "null") ? "" : fees_payee);
            FORM_DATA.append('fund_source', (fund_source === null) || (fund_source === "undefined" || fund_source === "null") || (fund_source === undefined) ? "" : fund_source);
            FORM_DATA.append('source_name',(source_name === null) || (source_name === "undefined" || (source_name === "null") || (source_name === undefined)) ? "" : source_name);
            FORM_DATA.append('source_statement', source_statement);
            FORM_DATA.append('sponsor_first_name',(sponsor_first_name === null) || (sponsor_first_name === "undefined") || (sponsor_first_name === undefined) || (sponsor_first_name === "null") ? "" : sponsor_first_name);
            FORM_DATA.append('sponsor_middle_name', (sponsor_middle_name === null) || (sponsor_middle_name === "undefined") || (sponsor_middle_name === undefined) || (sponsor_middle_name === "null") ? "" : sponsor_middle_name);
            FORM_DATA.append('sponsor_last_name', (sponsor_last_name === null) || (sponsor_last_name === "undefined") || (sponsor_last_name === undefined) || (sponsor_last_name === "null") ? "" : sponsor_last_name);
            FORM_DATA.append('sponsor_relationship',  (sponsor_relationship === null) || (sponsor_relationship === "undefined") || (sponsor_relationship === undefined) || (sponsor_relationship === "null") ? "" : sponsor_relationship);
            FORM_DATA.append('sponsor_citizenship', (sponsor_citizenship === null) || (sponsor_citizenship === "undefined") || (sponsor_citizenship === undefined) || (sponsor_citizenship === "null") ? "" : sponsor_citizenship);
            FORM_DATA.append('sponsor_address1', (sponsor_address1 === null) || (sponsor_address1 === "undefined") || (sponsor_address1 === undefined) || (sponsor_address1 === "null") ? "" : sponsor_address1);
            FORM_DATA.append('sponsor_address2',(sponsor_address2 === null) || (sponsor_address2 === "undefined") || (sponsor_address2 === undefined) || (sponsor_address2 === "null") ? "" : sponsor_address2);
            FORM_DATA.append('sponsor_address_city', (sponsor_address_city === null) || (sponsor_address_city === "undefined") || (sponsor_address_city === undefined) || (sponsor_address_city === "null") ? "" : sponsor_address_city);
            FORM_DATA.append('sponsor_address_state', (sponsor_address_state === null) || (sponsor_address_state === "undefined") || (sponsor_address_state === undefined) || (sponsor_address_state === "null") ? "" : sponsor_address_state);
            FORM_DATA.append('sponsor_address_postal', (sponsor_address_postal === null) || (sponsor_address_postal === "undefined") || (sponsor_address_postal === undefined) || (sponsor_address_postal === "null") ? "" : sponsor_address_postal);
            FORM_DATA.append('sponsor_address_country', (sponsor_address_country === null) || (sponsor_address_country === "undefined") || (sponsor_address_country === undefined) || (sponsor_address_country === "null") ? "" : sponsor_address_country);
            FORM_DATA.append('sponsor_phone',(sponsor_phone === null) || (sponsor_phone === "undefined") || (sponsor_phone === undefined) || (sponsor_phone === "null") ? "" : sponsor_phone);
            FORM_DATA.append('sponsor_email',(sponsor_email === null) || (sponsor_email === "undefined") || (sponsor_email === undefined) || (sponsor_email === "null") ? "" : sponsor_email);

            const API_RESPONSE = await FINANCE_DECLARATION_SAVE(FORM_DATA);
            if (API_RESPONSE?.data?.status) {
                localStorage.setItem('finance_declaration_information_status', API_RESPONSE?.data?.finances_save_status);
                if (API_RESPONSE?.data?.finances_save_status == "1") {

                    notification.open({
                        message: "Success!!",
                        description: "Finance Declaration Save as Draft.",
                        icon: <SmileOutlined style={{ color: "green" }} />,
                    });
                    set_loader(false);
                    VIEW_FINANCE_DECLARATION();
                }

                if (API_RESPONSE?.data?.finances_save_status == "2") {
                    notification.open({
                        message: "Success!!",
                        description: "Finance Declaration Saved Sucessfully",
                        icon: <SmileOutlined style={{ color: "green" }} />,
                    });
                    set_loader(false);
                    VIEW_FINANCE_DECLARATION();
                }
            } else {
                set_errors(API_RESPONSE?.data?.errors);
                set_loader(false);
            }
        }
    }

    const VIEW_FINANCE_DECLARATION = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', atob(id));
        const VIEW_FINANCE_DECLARATION_API_RESPONSE = await GET_FINANCE_DECLARATION(FORM_DATA);
        if (VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.status) {
            localStorage.setItem('finance_declaration_information_status', VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.finances_save_status);
            set_loader(false);
            set_fees_payee(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.fees_payee);
            set_fund_source(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.fund_source)
            set_source_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.source_name);
            api_set_source_statement(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.source_statement);
            set_sponsor_first_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_first_name);
            set_sponsor_middle_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_middle_name);
            set_sponsor_last_name(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_last_name);
            set_sponsor_relationship(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_relationship);
            set_sponsor_citizenship(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_citizenship);
            set_sponsor_address1(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address1);
            set_sponsor_address2(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address2);
            set_sponsor_address_city(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_city);
            set_sponsor_address_state(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_state);
            set_sponsor_address_postal(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_postal);
            set_sponsor_address_country(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_address_country);
            set_sponsor_phone(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_phone);
            set_sponsor_email(VIEW_FINANCE_DECLARATION_API_RESPONSE?.data?.finances?.sponsor_email);
        }
        else {
            set_loader(false);
        }
    }

    return (<>

        {loader ?
            <>
                <SisLoader />
            </>
            :
            <>
                <div>
                    <br />
                    <hr />
                    <br />
                    <div className="theme-content-left-head">
                        <h3>Applicant's Declaration of Finances</h3>
                    </div>
                    <br />
                </div>
                <div className="row">
                    <p style={{ paddingLeft: "10px" }}>All international applicants are required to complete this form and provide financial support documents to demonstrate their ability to pay all tution, fees, and living expenses for the first year of the academic program. An applicant or a sponsor must submit a bank statement or a verification letter from an officer of the bank or other financial institution giving the present balance, which must equal or exceed the amount required for one year of study ($20,065).</p>
                </div>
                <br />
                <div className="row">
                    <div className="col-5">
                        <div className="input-box">
                            <label>
                                Who will pay for your educational expenses at Lincoln University?
                            </label>
                            <Select
                             disabled={check_permissions('enrollments', 'update') ?
                                false : true
                            }
                                value={fees_payee}
                                style={{ width: "100%", height: "40px" }}
                                onChange={(value) => set_fees_payee(value)}
                                placeholder="--Select Payee-"
                            >
                                <Select.Option value="">-Please select one option-</Select.Option>
                                <Select.Option value="Myself">Myself</Select.Option>
                                <Select.Option value="Sponsor">Sponsor</Select.Option>
                            </Select>
                            {errors?.fees_payee && (
                                <>
                                    <span style={{ color: "red" }}>{errors?.fees_payee[0]}</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <p style={{ paddingLeft: "10px" }}>The funds for my educational expenses are on deposit in a bank or in a financial institution allowing me to use them for my educational purposes. (please choose one)</p>
                </div>

                {fees_payee == "Myself" &&
                    <>
                        <div className="row">
                            <div className="col-10">
                                <div className="input-box">
                                    <label>
                                        Source of Funds
                                    </label>
                                    <Radio.Group
                                     disabled={check_permissions('enrollments', 'update') ?
                                        false : true
                                    }
                                        style={{ width: "100%" }}
                                        value={fund_source}
                                        onChange={(e) => set_fund_source(e.target.value)}>
                                        <Radio value="bank">Bank: Please upload below a bank statement verifying that these funds are deposited in bank on my name and available for my educational use.</Radio>
                                        <Radio value="financial institution">Financial Institution: Please upload below a letter/statement verifying that the funds will be made available for my educational use in the U.S.A.</Radio>
                                    </Radio.Group>
                                    {errors?.fund_source && (
                                        <>
                                            <span style={{ color: "red" }}>{errors?.fund_source[0]}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="input-box">
                                    <label>
                                        Name of Bank/Financial Institution
                                    </label>
                                    <Input
                                    disabled={check_permissions('enrollments', 'update') ?
                                        false : true
                                    }
                                        value={source_name}
                                        placeholder="Name of Bank/Financial Institution"
                                        onChange={(e) => (set_source_name(e.target.value))}
                                    />
                                    {errors?.source_name && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.source_name[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="row">
                    <div className="col-6">
                        <div className="input-box" style={{ position: "relative" }}>
                            <label style={{ textTransform: "none" }}>
                                Please upload bank statement or financial institution letter/statement
                            </label>
                            <label className="upload-box upload-box-2">
                                <input
                               disabled={check_permissions('enrollments', 'update') ?
                                false : true
                            }
                                    ref={sourceStatementImage}
                                    type="file"
                                    id="upload-source-statement-images"
                                    style={{ height: "0px", overflow: "hidden" }}
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            "source_statement"
                                        )
                                    }
                                />
                                <CloudUploadOutlined />
                                Upload

                            </label>
                            {source_statement ? (
                                <>
                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                        <Button onClick={() => window.open(URL.createObjectURL(source_statement))}>Preview</Button>
                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "source_statement")}
                                        >
                                            <CloseOutlined />
                                        </button>
                                    </div>

                                </>
                            ) : (
                                <>
                                    {api_source_statement && (
                                        <>
                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(SIS_STORAGE + "/documents/" + api_source_statement)}>Preview</Button>

                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {errors?.source_statement && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.source_statement[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {fees_payee == "Sponsor" &&
                    <>
                        <div>
                            <br />
                            <hr />
                            <br />
                            <div className="theme-content-left-head">
                                <h3>Sponsor's Information</h3>
                            </div>
                            <br />
                        </div>
                        <div className="row">
                            {/* First Name */}
                            <div className="col-4">
                                <div className="input-box">
                                    <label>
                                        First Name
                                    </label>
                                    <Input
                                   disabled={check_permissions('enrollments', 'update') ?
                                    false : true
                                }
                                        value={sponsor_first_name}
                                        placeholder="First Name"
                                        onChange={(e) => set_sponsor_first_name(e.target.value)}
                                    />
                                    {errors?.sponsor_first_name && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.sponsor_first_name[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* Middle Name */}
                            <div className="col-4">
                                <div className="input-box">
                                    <label>Middle Name (Optional)</label>
                                    <Input
                                   disabled={check_permissions('enrollments', 'update') ?
                                    false : true
                                }
                                        value={sponsor_middle_name}
                                        placeholder="Middle Name"
                                        onChange={(e) => set_sponsor_middle_name(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* Last Name */}
                            <div className="col-4">
                                <div className="input-box">
                                    <label>
                                        Last Name
                                    </label>
                                    <Input
                                    disabled={check_permissions('enrollments', 'update') ?
                                        false : true
                                    }
                                        value={sponsor_last_name}
                                        placeholder="Last Name"
                                        onChange={(e) => set_sponsor_last_name(e.target.value)}
                                    />
                                    {errors?.sponsor_last_name && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.sponsor_last_name[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="input-box">
                                    <label>
                                        Sponsor's relationship to Student
                                    </label>
                                    <Input
                                     disabled={check_permissions('enrollments', 'update') ?
                                        false : true
                                    }
                                        value={sponsor_relationship}
                                        placeholder="Sponsor Relationship"
                                        onChange={(e) => set_sponsor_relationship(e.target.value)}
                                    />
                                    {errors?.sponsor_relationship && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.sponsor_relationship[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* Citizenship */}
                            <div className="col-4">
                                <div className="input-box">
                                    <label style={{ textTransform: "none" }}>
                                        Sponsor's Country of Citizenship
                                    </label>
                                    <CountryDropdown
                                  disabled={check_permissions('enrollments', 'update') ?
                                    false : true
                                }
                                        style={{ width: "100%", height: "40px" }}
                                        onChange={(value) => set_sponsor_citizenship(value)}
                                        value={sponsor_citizenship}
                                        className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                                    />
                                    {errors?.sponsor_citizenship && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.sponsor_citizenship[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* sponsor Address */}
                        <div style={{ marginTop: "20px" }}>
                            <label className="cus-label">
                                Sponsor's Address
                            </label>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-box">
                                        <label>
                                            Address Field 1
                                        </label>
                                        <Input
                                        disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            value={sponsor_address1}
                                            placeholder="Address"
                                            onChange={(e) => set_sponsor_address1(e.target.value)}
                                        />
                                        {errors?.sponsor_address1 && (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    {errors?.sponsor_address1[0]}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Mailing Address2 */}
                                <div className="col-6">
                                    <div className="input-box">
                                        <label>
                                            Address Field 2
                                        </label>
                                        <Input
                                        disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            value={sponsor_address2}
                                            placeholder="Address"
                                            onChange={(e) => set_sponsor_address2(e.target.value)}
                                        />
                                        {errors?.sponsor_address2 && (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    {errors?.sponsor_address2[0]}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* country */}
                                <div className="col-3">
                                    <div className="input-box">
                                        <label htmlFor="country">
                                            Country
                                        </label>
                                        <CountryDropdown
                                        disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            style={{ width: "100%", height: "40px" }}
                                            placeholde="--Select Country--"
                                            value={sponsor_address_country}
                                            onChange={(value) => set_sponsor_address_country(value)}
                                            className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                                        />
                                        {errors?.sponsor_address_country && (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    {errors?.sponsor_address_country[0]}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* city */}
                                <div className="col-3">
                                    <div className="input-box">
                                        <label htmlFor="email">
                                            City
                                        </label>
                                        <Input
                                        disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            value={sponsor_address_city}
                                            placeholder="City"
                                            onChange={(e) => set_sponsor_address_city(e.target.value)}
                                        />
                                        {errors?.sponsor_address_city && (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    {errors?.sponsor_address_city[0]}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* state */}
                                <div className="col-3">
                                    <div className="input-box">
                                        <label htmlFor="email">
                                            State
                                        </label>
                                        <Input
                                         disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            value={sponsor_address_state}
                                            placeholder="State"
                                            onChange={(e) => set_sponsor_address_state(e.target.value)}
                                        />
                                        {errors?.sponsor_address_state && (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    {errors?.sponsor_address_state[0]}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Postal Code */}
                                <div className="col-3">
                                    <div className="input-box">
                                        <label htmlFor="email">
                                            Zip / Postal Code
                                        </label>
                                        <Input
                                        disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            value={sponsor_address_postal}
                                            placeholder="Zip/Postal"
                                            onChange={(e) =>
                                                set_sponsor_address_postal(e.target.value)
                                            }
                                            keyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors?.sponsor_address_postal && (
                                            <>
                                                <span style={{ color: "red" }}>
                                                    {errors?.sponsor_address_postal[0]}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Mailing Address1 */}
                          
                        </div>

                        <div style={{ marginTop: "20px" }}>
                            <label className="cus-label">
                                Sponsor's Contact Details
                            </label>
                            <div className="row">
                                {/* Phone Number */}
                                <div className="col-4">
                                    <div className="input-box">
                                        <label htmlFor="phone">
                                            Sponsor's Phone
                                        </label>
                                        <Input
                                        disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            maxLength={12}
                                            value={sponsor_phone}
                                            placeholder="Phone Number"
                                            keyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            id=" Sponsor Phone"
                                            // onChange={(e) => set_phone(e.target.value)}
                                            onChange={(e) => {
                                                const phoneNumber = e.target.value;
                                                // Remove non-digit characters from the input
                                                const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
                                                if (cleanedPhoneNumber.length <= 10) {
                                                    set_Phone_Error('')
                                                    // Format the phone number as 510-628-8022
                                                    let formattedPhoneNumber = '';
                                                    for (let i = 0; i < cleanedPhoneNumber.length; i++) {
                                                        if (i === 3 || i === 6) {
                                                            formattedPhoneNumber += '-';
                                                        }
                                                        formattedPhoneNumber += cleanedPhoneNumber[i];
                                                    }
                                                    set_sponsor_phone(formattedPhoneNumber);
                                                    set_Phone_Error('')
                                                }
                                                else {
                                                    set_sponsor_phone(cleanedPhoneNumber)
                                                    set_Phone_Error("Phone Number Maximum Length Should be 10 Digit ")
                                                }
                                            }}
                                        />
                                        {errors?.sponsor_phone && (
                                            <>
                                                <span style={{ color: "red" }}>{errors?.sponsor_phone[0]}</span>
                                            </>
                                        )
                                            || phoneerror && (<><span style={{ color: "red" }}>{phoneerror}</span></>)
                                        }
                                    </div>
                                </div>
                                {/* sponsor Email */}
                                <div className="col-4">
                                    <div className="input-box">
                                        <label htmlFor="email">
                                            Sponsor's Email
                                        </label>
                                        <Input
                                         disabled={check_permissions('enrollments', 'update') ?
                                            false : true
                                        }
                                            value={sponsor_email}
                                            id="sponsor email"
                                            placeholder="example123@gmail.com"
                                            onChange={(e) => set_sponsor_email(e.target.value)}
                                        />
                                        {errors?.sponsor_email && (
                                            <>
                                                <span style={{ color: "red" }}>{errors?.sponsor_email[0]}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }
                 {check_permissions('enrollments', 'update') &&
                <div className="row">
                    <div className="col-2">
                        <div className="input-box">
                            {loader ? (
                                <>
                                    <Button type="primary">
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                            }
                                        />{" "}
                                        Saving
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button style={{backgroundColor:"#B64141"}}
                                         onClick={() => { FINANCE_DECLARATION_DRAFT_NEXT(2) }} 
                                        type="primary">
                                        Save
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
}
            </>
        }
    </>
    );
}

export default FinanceDeclaration;